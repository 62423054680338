/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import '../styles/Pages/forgot_password/styles.scss';
import { SERVER_URL } from '../utils/environment';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sent_reset: false,
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  forgotPassword = () => {
    axios
      .post(`${SERVER_URL}/api/v2/forgot_passwords`, { email: this.state.email })
      .then(() => {
        this.setState({ sent_reset: true });
      })
      .catch(() => {
        this.setState({ sent_reset: true });
      });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.forgotPassword();
  };

  render() {
    const { sent_reset, email } = this.state;
    return (
      <Layout location={this.props.location}>
        <div className="forgot-password">
          <div className="password-container">
            <h1>Forgot Password</h1>
            <p>
              Already have your password?{' '}
              <b>
                <Link style={{ color: '#2e5fca' }} title="Sign In" to="/?signin=true">
                  Sign In
                </Link>
              </b>
            </p>
            <form onSubmit={this.handleSubmit}>
              <label>Email</label>
              <input name="email" type="text" onChange={this.handleChange} />
              <button disabled={sent_reset} type="submit">
                SEND PASSWORD RESET
              </button>
            </form>
            {sent_reset && (
              <p>
                If an account exists with <b>{email}</b>, we will send password reset
                instructions to you shortly.
              </p>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

ForgotPassword.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ForgotPassword;
